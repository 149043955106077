<template>
  <div class="all">
    <!--    顶部Banner-->
    <div class="topTitle">
      <div class="title">
        <!--        顶部logo-->
        <div class="imgLogo" @click="home"><img src="../../assets/img/logo2.png" class="home">
          <p>环宇建筑科技股份有限公司</p>
        </div>
      </div>
      <div class="cont">
        <div class="login">
          <span>登录</span>
          <div class="input_two">
            <div class="input_phone"><img src="../../assets/img/phone.png">
              <el-input v-model="phone" class="input_i" maxlength="11" placeholder="请输入您的手机号"
                        @change="phone_gz"></el-input>
            </div>
            <div class="input_phone"><img src="../../assets/img/password.png">
              <el-input v-model="pwd" class="input_p" placeholder="请输入您的密码" show-password></el-input>
            </div>
            <router-link :to="{name:'update_pwd'}" class="update_pwd"> 忘记密码</router-link>
          </div>
          <div class="login_d" @click="login">登录</div>
          <div class="tab_but">
            <router-link :to="{name:'quick_login'}" class="text_login" style="padding: 0px 20px"> 手机快捷登录</router-link>
            <router-link :to="{name:'registration'}" class="text_login"><p>注册</p></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { VtPhone } from '@/api/api'

export default {
  name: 'login',
  data () {
    return {
      phone: '',
      pwd: ''
    }
  },
  created () {
    const data = {
      userName: 'euRest',
      password: 'euRest123456',
      // password: '123456',
      loginName: '王莉莉'
    }
    axios.request({
      baseURL: '',
      url: 'api/seeyon/rest/token',
      method: 'POST',
      data
    }).then((response) => {
      localStorage.setItem('token', response.data.id)
    })
  },
  methods: {
    phone_gz () {
      if (!/^1[0-9]{10}$/.test(this.phone)) {
        this.phone = ''
        this.$message.error('请输入正确的电话号码')
      }
    },
    login () {
      if (!/^1[0-9]{10}$/.test(this.phone)) {
        this.phone = ''
        this.$message.error('请输入正确的电话号码')
      } else if (this.pwd === null || this.pwd === '') {
        this.$message.error('请输入密码')
      } else {
        axios.defaults.headers.token = localStorage.getItem('token')
        VtPhone({
          method: 'login',
          phone: this.phone,
          loginType: 'password',
          password: this.pwd,
          authCode: ''
        }, res => {
          if (res.data.data === null) {
            this.$message.error(res.data.message)
          } else if (res.data.success === true) {
            this.$cookies.set('EUJSESSIONID', decodeURIComponent(res.data.data.EUJSESSIONID))
            localStorage.setItem('EUJSESSIONID', decodeURIComponent(res.data.data.EUJSESSIONID))
            localStorage.setItem('id', res.data.data.member.id) // 登录ID
            localStorage.setItem('supplyType', res.data.data.member.supplyType) // 登录判断企业|个人用户
            localStorage.setItem('unitId', res.data.data.member.unitId) // 登录ID
            localStorage.setItem('accountType', res.data.data.member.accountType) // 判断是子账户还是主账户
            this.$message.success('登录成功！')
            this.$router.replace({ name: 'Home' })
          }
        })
      }
    },
    home () {
      this.$router.replace('/')
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.home{
  cursor: pointer;
}
.all {
  width: 100%;
  height: 1100px;
  margin: 0 auto;
}
/*顶部title*/
.topTitle {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-image: url("../../assets/img/login_bk.png");
  background-size: 100% 100%;
}

.title {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 90px;
  background-color: black;
  opacity: 0.68;
  margin-bottom: 90px;
}

.imgLogo > img {
  width: 359px;
  height: 54px;
  padding-top: 10px;
}
.imgLogo >p{
  color: #FFFFFF;
  line-height: 10px;
  padding-left: 67px;
  letter-spacing: 1px;
  font-size: 23.2px;
  cursor: pointer;
  font-weight: bold;
}
.cont {
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  width: 100%;
  height: 80%;
  /*text-align: center;*/
  margin: 0 auto;
}

/*登录框*/
.login {
  width: 400px;
  height: 390px;
  background-color: white;
  margin: 0 auto;
  border-radius: 10px;
  text-align: center;
}

.login > span {
  font-size: 18px;
  color: #323333;
  line-height: 88px;
  letter-spacing: 1px;
}

.input_phone {
  position: relative;
}

/*input框*/
.input_phone > img {
  /*position: absolute;*/
  margin-left: -20px;
  margin-top: 30px;
  width: 20px;
  height: 20px;
}

.input_i {
  width: 300px;
  margin-left: 10px;
  margin-top: 20px;
}

.input_p {
  width: 300px;
  margin-left: 14px;
  margin-top: 20px;
}

/*忘记密码*/
.update_pwd {
  font-size: 14px;
  color: #EA5D5C;
  line-height: 40px;
  margin-left: 270px;
  cursor: pointer;
}

/*登录按钮*/
.login_d {
  width: 320px;
  height: 50px;
  background-color: #10539B;
  font-size: 16px;
  text-align: center;
  line-height: 45px;
  color: white;
  margin-left: 47px;
  border-radius: 4px;
  margin-top: 19px;
  letter-spacing: 1px;
  cursor: pointer;
}

/*跳转*/
.tab_but {
  display: flex;
  justify-content: space-between;
  padding: 19px 30px;
}

.text_login {
  color: #666666;
  font-size: 14px;
  cursor: pointer;
}
</style>
